/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IS_DEV } from '../consts';

const logger = IS_DEV
  ? {
      debug: (...message: any[]) => console.debug(...message),
      info: (...message: any[]) => console.log(...message),
      warn: (...message: any[]) => console.warn(...message),
      error: (...message: any[]) => console.error(...message),
    }
  : {
      debug: (..._: any[]) => {},
      info: (..._: any[]) => {},
      warn: (..._: any[]) => {},
      error: (..._: any[]) => {},
    };

export default logger;
