import { FC, useContext, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ReCoPoError from 'utils/ReCoPoError';
import useBackPreventer from 'utils/useBackPreventer';

import spinner from '../../assets/spinner.gif';
import Card from '../../components/molecules/Card';
import LoadingMessage from '../../components/molecules/LoadingMessage';
import { HeaderInfoContext } from '../../contexts/HeaderInfoContext';
import { paths } from '../routes';
import useCouponPage from './useCouponPage';

const CouponPage: FC = () => {
  const { qId } = useParams();
  if (qId === undefined) throw new ReCoPoError('URLが不正です');

  const {
    spendCoupon,
    coupon,
    isLoading,
    isError,
    discountRate,
    adDiscountRate,
    deadline,
    isCouponSpending,
    isCouponSpendError,
  } = useCouponPage(qId);

  const { setHeaderInfo } = useContext(HeaderInfoContext);

  useEffect(() => {
    setHeaderInfo({ pageName: 'クーポン利用' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBackPreventer('アンケート画面へは戻れません');

  if (isLoading)
    return (
      <div>
        <p>
          クーポン情報をロード中
          <img src={spinner} alt="loading-spinner" />
        </p>
      </div>
    );
  if (isError) return <Navigate to={paths.error} />;

  return (
    <div>
      {adDiscountRate > 0 && (
        <p className="p-1 text-sm">
          アンケートに回答していただき
          <br />
          ありがとうございました !!
        </p>
      )}
      <div>
        <Card title="" className="my-4">
          <h2 className="text-2xl">{coupon?.branchName}</h2>
          <p className="text-xl text-emphasize">{discountRate + adDiscountRate} % 割引</p>
          <p>抽選分: {discountRate} %</p>
          <p>アンケート回答分: {adDiscountRate} %</p>
          <p>有効期限: 今回のお食事 ( {deadline.toLocaleString()} ) まで</p>
          <p className="text-xs">※ クーポンの併用はできません。</p>
        </Card>
      </div>
      <p className="text-sm">この画面を店員に見せてください</p>
      <button
        onClick={spendCoupon}
        disabled={isCouponSpending}
        className={`${
          isCouponSpending ? 'bg-grey' : 'bg-emphasize'
        } text-white font-bold py-2 px-4 rounded w-full`}
      >
        クーポンを使う
      </button>
      {isCouponSpending && <LoadingMessage message="クーポンを利用しています" />}
      {isCouponSpendError && <p>クーポンの利用に失敗しました</p>}
    </div>
  );
};

export default CouponPage;
