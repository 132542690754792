import thankyou from 'assets/thankyou.svg';
import { FC } from 'react';
// import { AnalyticsEvent, sendLog } from "../../utils/analytics";
import { useSearchParams } from 'react-router-dom';
import useHeader from 'services/useHeader';
import useBackPreventer from 'utils/useBackPreventer';

const CompletedPage: FC = () => {
  useHeader('');
  useBackPreventer();

  const [searchParams] = useSearchParams();
  const isCouponUsed = searchParams.get('isCouponUsed') === 'true';

  return (
    <div>
      <div className="flex flex-col space-y-6">
        {isCouponUsed && (
          <div className="mb-4">
            <p className="text-lg">クーポンを利用しました。</p>
          </div>
        )}

        <img src={thankyou} alt="回答ありがとうございます。" className="h-44" />
        <p>ReCoPoをご利用いただき、ありがとうございました。</p>
        <p>お店での残りの時間も、ごゆっくりお楽しみください。</p>
        <div>
          {/*
            <p className="text-xs">
            ReCoPoはアプリで、より手軽にご利用いただけます。
          </p>
          <div className="grid grid-cols-2">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.recopoApp.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              rel="noreferrer"
              onClick={() => sendLog(AnalyticsEvent.GoStoreAndroid)}
            >
              <img
                alt="Google Play で手に入れよう"
                src="https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png"
              />
            </a>
          </div> */}
          <a target="_blank" href="https://forms.gle/kngKchrPpmqKbNsR6" rel="noreferrer">
            <p className="text-xs text-link underline">
              ReCoPoをより良くするご意見を募集しています。
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CompletedPage;
