import firebase from 'firebase/app';
// 必要に応じて追加する
import 'firebase/auth';
// eslint-disable-next-line import/order
import 'firebase/analytics';

import { IS_USING_LOCAL_AUTH } from '../consts';

if (typeof window !== 'undefined' && !firebase.apps.length) {
  firebase.initializeApp(JSON.parse(process.env['REACT_APP_FIREBASE_CONFIG'] as string));
}

if (IS_USING_LOCAL_AUTH) {
  firebase.auth().useEmulator('http://localhost:9099');
}
export default firebase;
export const auth = firebase.auth();

export const analytics = firebase.analytics();
