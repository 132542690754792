import { FC } from 'react';
import { Routes, Route } from 'react-router';

import BranchInfoPage from './branch';
import BranchInfoRedirector from './branch/branchInfoRedirector';
import CompletedPage from './complete';
import CouponPage from './coupon';
import FlowPages from './flow';
import NotFoundPage from './notFound';
import OnlyQuestionnairePage from './only-questionnaire/Index';
import { paths } from './routes';
import PersonalInfoPage from './userInfo';

const Pages: FC = () => (
  <Routes>
    {/* branchInfo?bId=:bId にリクエストが有った時にリダイレクトする。 */}
    <Route path="/branchInfo" element={<BranchInfoRedirector />} />
    <Route path={paths.branch} element={<BranchInfoPage />} />
    <Route path={`${paths.flow}/*`} element={<FlowPages />} />
    <Route path={paths.coupon} element={<CouponPage />} />
    <Route path={paths.completed} element={<CompletedPage />} />
    <Route path={paths.userInfo} element={<PersonalInfoPage />} />
    <Route path={paths.questionnaireWithOutLotteryAndCoupon} element={<OnlyQuestionnairePage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default Pages;
