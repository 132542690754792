import hazurePiyo from 'assets/piyo-hazure.svg';
import { FC } from 'react';

import Card from '../../../../components/molecules/Card';

export const HazureCard: FC = () => {
  return (
    <Card title="" className="text-center  border-4 border-grey">
      <div className="flex  items-end text-4xl">
        <img src={hazurePiyo} alt="はずれ" className="mr-4 w-16" />
        <p className="text-4xl font-extrabold text-text">はずれ</p>
      </div>
    </Card>
  );
};
