import spinner from 'assets/spinner.gif';
import { createContext, FC, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../infrastructure/firebase';

export const AuthContext = createContext({
  uId: '',
  token: '',
});

const AuthProvider: FC = ({ children }) => {
  const [user] = useAuthState(auth);
  useEffect(() => {
    //初回のみ匿名ユーザーとしてログイン/ 登録
    auth.signInAnonymously();
  }, []);

  useEffect(() => {
    const f = async () => {
      if (user) {
        const token = await user.getIdToken();
        setAuthContext({ uId: user.uid, token });
      }
    };
    f();
  }, [user]);

  const [authContext, setAuthContext] = useState({
    uId: '',
    token: '',
  });

  const isReady = authContext.uId.length > 0 && authContext.token.length > 0;
  if (isReady) {
    return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
  } else {
    return (
      <div className="flex justify-center my-20 mx-5 md:container md:mx-auto ">
        <p>
          アプリケーションをロード中
          <img src={spinner} alt="loading-spinner" />
        </p>
      </div>
    );
  }
};
export default AuthProvider;
