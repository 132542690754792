import LoadingMessage from 'components/molecules/LoadingMessage';
import { FC } from 'react';

import useAddressInput from './useAddressInput';

type Props = {
  setPrefecture: (p: string) => void;
  setMunicipality: (p: string) => void;
  prefecture: string;
  municipality: string;
};

const AddressInput: FC<Props> = ({ setPrefecture, setMunicipality, prefecture, municipality }) => {
  const { address, checkZipcode, searchAddress, errorMessage, isLoading } = useAddressInput({
    setPrefecture,
    setMunicipality,
    prefecture,
    municipality,
  });

  return (
    <div>
      <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700" htmlFor="zip">
        郵便番号
      </label>
      <input
        className="block py-3 px-4  w-full leading-tight text-gray-700 focus:bg-white rounded border border-gray-200 focus:border-gray-500 focus:outline-none appearance-none"
        id="zip"
        type="number"
        onChange={(e) => {
          searchAddress(e.target.value);
        }}
        onBlur={(e) => {
          checkZipcode(e.target.value);
        }}
        pattern="\d{7}"
      />
      {address ? (
        <span className="text-xs">{address}</span>
      ) : isLoading ? (
        <LoadingMessage message="検索しています。" />
      ) : errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        <p className="text-xs text-grey">
          例: 3050043
          <span className="ml-1">(半角で入力してください)</span>
        </p>
      )}
    </div>
  );
};

export default AddressInput;
