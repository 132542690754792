import atariPiyo from 'assets/piyo-atari.svg';
import { FC } from 'react';

import Card from '../../../../components/molecules/Card';

type AtariCardProps = {
  discountRate: number;
};
export const AtariCard: FC<AtariCardProps> = ({ discountRate }) => (
  <Card title="" className="text-center  border-4 border-emphasize">
    <p className="text-6xl font-extrabold text-emphasize">当選</p>
    <div className="flex items-end  text-4xl text-emphasize">
      <img src={atariPiyo} alt="あたり" className="w-20" />
      <span className="font-bold">{discountRate * 100}% OFF</span>
    </div>
  </Card>
);
