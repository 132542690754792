// TODO: models/coupon的なとこに移動

import { Coupon } from '../models/coupon';

const isValidCoupon = (c: Coupon) => {
  const deadline = new Date(c.issueDate);
  deadline.setHours(deadline.getHours() + 3);
  return c.isUsed === false && c.issueDate && deadline > new Date(); //有効期限のほうが現在時刻よりも未来のとき
};

export default isValidCoupon;
