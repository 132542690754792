import { useParams } from 'react-router-dom';

import ReCoPoError from './ReCoPoError';

const useBid = () => {
  const unsafeBId = useParams().bId;
  if (!unsafeBId) {
    throw new ReCoPoError('店舗が見つかりませんでした');
  }
  return unsafeBId;
};

export default useBid;
