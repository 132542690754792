import Skeleton from 'components/atoms/skeleton';
import LoadingMessage from 'components/molecules/LoadingMessage';
import { affiliations } from 'consts';
import { BranchInfo } from 'models/branchInfo';
import { FC, VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import useHeader from 'services/useHeader';

import AddressInput from './components/addressInput';
import usePersonalInfoPage from './usePersonalInfoPage';

const PersonalInfoPage: FC = () => {
  const {
    submit,
    isValid,
    register,
    handleSubmit,
    isPosting,
    errMsg,
    prefecture,
    setPrefecture,
    municipality,
    setMunicipality,
    branch,
  } = usePersonalInfoPage();

  useHeader('あなたについて', branch?.isUseLotteryAndCoupon ? 25 : 50);

  return (
    <div className="flex flex-col space-y-6 w-full">
      <div>
        <h2 className="text-lg">あなたについて教えて下さい。</h2>
        <span className="text-xs">
          {branch && <>頂いた情報は{branch.branchName}をより良くするために使われます。</>}
          {!branch && <Skeleton />}
        </span>
      </div>
      <form
        className="flex flex-col my-8 space-y-4 w-full max-w-lg"
        onSubmit={handleSubmit(submit)}
      >
        <AddressInput
          setPrefecture={setPrefecture}
          setMunicipality={setMunicipality}
          prefecture={prefecture}
          municipality={municipality}
        />
        <AgeInput
          register={register('age', {
            required: true,
            min: 4,
            max: 115,
          })}
        />
        <GenderInput register={register('gender', { required: true })} />
        <AffiliationSelect register={register('affiliation', { required: true })} />
        <SubmitButton branch={branch} isSubmittable={isValid && !isPosting} />

        {isPosting && <LoadingMessage />}
        {errMsg}
      </form>
    </div>
  );
};

type AgeInputProps = { register: UseFormRegisterReturn };
const AgeInput: VFC<AgeInputProps> = ({ register }) => (
  <div>
    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700" htmlFor="age">
      年齢
    </label>
    <input
      className="block py-3 px-4 w-full leading-tight text-gray-700 focus:bg-white rounded border border-gray-200 focus:border-gray-500 focus:outline-none appearance-none"
      id="age"
      min="4"
      max="115"
      type="number"
      {...register}
    />
  </div>
);

type GenderInputProps = { register: UseFormRegisterReturn };
const GenderInput: VFC<GenderInputProps> = ({ register }) => (
  <div>
    <label
      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
      htmlFor="grid-state"
    >
      性別
    </label>
    <div className="relative">
      <label className="pr-4">
        <input type="radio" {...register} value="男性" className="mr-1" />
        男性
      </label>
      <label className="pr-4">
        <input type="radio" {...register} value="女性" className="mr-1" />
        女性
      </label>
      <label className="pr-4">
        <input type="radio" {...register} value="無回答" className="mr-1" />
        無回答
      </label>
    </div>
  </div>
);

type AffiliationSelectProps = { register: UseFormRegisterReturn };
const AffiliationSelect: VFC<AffiliationSelectProps> = ({ register }) => (
  <div>
    <label
      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
      htmlFor="grid-state"
    >
      ご職業
    </label>
    <div className="relative">
      <select
        className="block py-3 px-4 pr-8 w-full leading-tight text-gray-700 bg-white rounded border border-gray-200 focus:border-gray-500 focus:outline-none appearance-none"
        id="grid-state"
        {...register}
      >
        {affiliations.map((affiliation) => (
          <option value={affiliation} key={affiliation}>
            {affiliation}
          </option>
        ))}
      </select>
      {/* Select右の矢印 */}
      <div className="flex absolute inset-y-0 right-0 items-center px-2 text-gray-700 pointer-events-none">
        <svg
          className="w-4 h-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  </div>
);

type SubmitButtonProps = {
  isSubmittable: boolean;
  branch: BranchInfo | null;
};
const SubmitButton: VFC<SubmitButtonProps> = ({ branch, isSubmittable }) => {
  if (branch)
    return (
      <button
        type="submit"
        className={` ${
          isSubmittable ? 'bg-main' : 'bg-grey'
        } text-text font-bold py-2 px-4 rounded mt-8 w-full`}
        disabled={!isSubmittable}
      >
        {branch.isUseLotteryAndCoupon && '評価と抽選へ'}
        {!branch.isUseLotteryAndCoupon && 'お店について書く'}
      </button>
    );

  return <Skeleton height={40} />;
};
export default PersonalInfoPage;
