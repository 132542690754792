// TODO: configファイルに移動
export const IS_USING_LOCAL_SERVER = process.env['REACT_APP_IS_USING_LOCAL_SERVER'] === 'TRUE';
export const IS_USING_LOCAL_AUTH = process.env['REACT_APP_IS_USING_LOCAL_AUTH'] === 'TRUE';
export const IS_DEV = process.env['REACT_APP_IS_DEV'] === 'TRUE';

export const API_BASEURL = IS_USING_LOCAL_SERVER
  ? 'http://localhost:5001/table-customer-app-development/asia-northeast1'
  : IS_DEV
  ? 'https://asia-northeast1-table-customer-app-development.cloudfunctions.net'
  : 'https://asia-northeast1-recopo-production.cloudfunctions.net';

export const API_ENDPOINT_SUFFIX = 'V2';

export const affiliations = [
  '会社員・役員/自営業',
  'パート/アルバイト',
  '学生',
  '専業主婦・主夫',
  '無職',
] as const;
