import { analytics } from '../infrastructure/firebase';
export const LogEvent = {
  // FLUTTER と共通
  Button: 'Button',
  ClickSignUpButton: 'ClickSignUpButton',
  SignUpFailureByFireAuth: 'SignUpFailureByFireAuth',
  SignUpFailureOtherReason: 'SignUpFailureOtherReason',
  SignUpSuccess: 'SignUpSuccess',
  ClickLoginButton: 'ClickLoginButton',
  LoginFailure: 'LoginFailure',
  LoginSuccess: 'LoginSuccess',
  Logout: 'Logout',
  LogoutFailure: 'LogoutFailure',
  ReadQRCode: 'ReadQRCode',
  DrawLottery: 'DrawLottery',
  StartQuestinnaireAnswering: 'StartQuestinnaireAnswering',
  BackHomeWithoutAnswering: 'BackHomeWithoutAnswering',
  SendQuestionnaireAnswer: 'SendQuestionnaireAnswer',
  SendQuestionnaireAnswerFailure: 'SendQuestionnaireAnswerFailure',
  UseCoupon: 'UseCoupon',

  // WEBのみ
  GoFlow: 'GoFlow',
  GoStoreIOS: 'GoStoreIOS',
  GoStoreAndroid: 'GoStoreAndroid',
  GoCouponWithoutAnswering: 'UseCouponWithoutAnswering',
  UseCouponFailure: 'UseCouponFailure',
  SendQuestionnaireAnswerWithOutLottery: 'SendQuestionnaireAnswerWithOutLottery',
} as const;

export const sendLog = (
  eventName: typeof LogEvent[keyof typeof LogEvent],
  eventParams?:
    | {
        [key: string]: any;
      }
    | undefined,
) => {
  analytics.logEvent(eventName, eventParams);
};
