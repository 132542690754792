import { AuthContext } from 'contexts/AuthProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { postRequest } from 'repositories/apiClient';

import { BranchInfo as BranchInfoResponse } from '../models/branchInfo';

const getBranchInfo = async (bId: string, token: string) => {
  return await postRequest<BranchInfoResponse>('getBranchInfo', { bId }, token);
};

export const buildBranchCacheKey = (bId: string) => ['branch', bId];

const useBranchInfo = (
  bId: string,
): {
  data: BranchInfoResponse | null;
  isError: boolean;
  isLoading: boolean;
} => {
  const auth = useContext(AuthContext);
  const { isLoading, data, isError } = useQuery(buildBranchCacheKey(bId), () =>
    getBranchInfo(bId, auth.token),
  );

  return { data: data || null, isError, isLoading };
};

export default useBranchInfo;
