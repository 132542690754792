import ProgressBar from 'progressbar.js';
import { FC, useEffect, useRef } from 'react';

import { AppColor } from '../style/commonStyle';
import './ProgressBar.css';
interface Props {
  progress: number;
}

const Progressbar: FC<Props> = ({ progress }) => {
  const wrapperElem = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.document.getElementById('progress')?.remove();

    const progElem = window.document.createElement('div');
    progElem.id = 'progress';
    wrapperElem.current?.appendChild(progElem);

    const circle = new ProgressBar.Circle('#progress', {
      color: AppColor.sub,
      trailColor: '#eee',
      strokeWidth: 10,
      duration: 2500,
      easing: 'easeInOut',
    });
    circle.set(progress / 100);
    circle.setText(`${progress.toString()}%`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <div id="wrapper" ref={wrapperElem}>
      <div id="progress" />
    </div>
  );
};

export default Progressbar;
