import { buildFlowPath } from '../routes';

export const flowPaths = {
  lottery: 'lottery',
  lotteryResult: 'lotteryResult/:qId',
  questionnaire: 'questionnaire/:qId',
} as const;

export const buildLotteryPath = (bId: string) => `${buildFlowPath(bId)}/lottery`;
export const buildLotteryResultPath = (bId: string, qId: string) =>
  `${buildFlowPath(bId)}/lotteryResult/${qId}`;
export const buildQuestionnairePath = (bId: string, qId: string) =>
  `${buildFlowPath(bId)}/questionnaire/${qId}`;
