import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLotteryResult } from 'repositories/lottery/useLottery';
import ReCoPoError from 'utils/ReCoPoError';

import LoadingMessage from '../../../components/molecules/LoadingMessage';
import { HeaderInfoContext } from '../../../contexts/HeaderInfoContext';
import usePostQuestionnaire from '../../../repositories/usePostQuestionnaire';
import { LogEvent, sendLog } from '../../../utils/log';
import { buildCouponPath } from '../../routes';

const QuestionnairePage: FC = () => {
  const { qId } = useParams();
  if (qId === undefined) throw new ReCoPoError('URLが不正です');
  const { data: lotteryResult } = useLotteryResult(qId);
  const [answer, setAnswer] = useState('');
  const { postQuestionnaireFn, isSending } = usePostQuestionnaire(qId);
  const { setHeaderInfo } = useContext(HeaderInfoContext);
  useEffect(() => {
    setHeaderInfo({
      pageName: 'アンケート',
      backTo: -1,
      progress: 100,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [errMsg, setErrMsg] = useState('');

  const navigate = useNavigate();
  const answerChanged: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setAnswer(e.target.value);
  };

  const disabled = answer.length === 0 || isSending;

  const onSubmit = async () => {
    setErrMsg('');
    if (!postQuestionnaireFn || !lotteryResult) return;
    await postQuestionnaireFn(answer).then((data) => {
      if (!data) {
        setErrMsg('送信に失敗しました。');
        return;
      }
      navigate(buildCouponPath(lotteryResult.qId));
    });
  };
  return (
    <div>
      <p>
        利用したお店に対して、料理・雰囲気・場所・サービス・価格などについて、より具体的なご意見をお聞かせください。
      </p>
      <div className="flex justify-center h-96">
        <textarea
          onChange={(event) => answerChanged(event)}
          className="p-2 mt-2 w-full h-full rounded border-4 border-grey border-solid outline-none"
          disabled={isSending}
        />
      </div>
      <div className="flex justify-center pt-5">
        <button
          onClick={() => {
            onSubmit();
            sendLog(LogEvent.SendQuestionnaireAnswer);
          }}
          className={`${
            disabled ? 'bg-grey' : 'bg-main'
          } hover:bg-blue-700 text-blue font-bold py-2 px-4 rounded w-full`}
          disabled={disabled}
        >
          送信
        </button>
      </div>
      {isSending && <LoadingMessage />}
      {errMsg}
    </div>
  );
};

export default QuestionnairePage;
