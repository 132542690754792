import { FC } from 'react';

type Props = {
  title: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
};
const Card: FC<Props> = ({ title, footer, children, className }) => {
  return (
    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${className}`}>
      <div>
        <h2 className="text-3xl font-semibold text-gray-800">{title}</h2>
        <div className="mt-2 text-gray-600">{children}</div>
      </div>
      <div className="flex justify-end mt-4">{footer}</div>
    </div>
  );
};

export default Card;
