import { FC, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import image404 from '../../assets/404.svg';
import { IS_DEV } from '../../consts';
import { HeaderInfoContext } from '../../contexts/HeaderInfoContext';

const NotFoundPage: FC = () => {
  const { setHeaderInfo } = useContext(HeaderInfoContext);
  useEffect(() => {
    setHeaderInfo({ pageName: 'エラー' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="w-full text-center">
        <div className="flex justify-center pb-4">
          <img src={image404} alt="404" className="w-10/12" />
        </div>
        <p className="py-2 text-xl font-bold text-text">
          お探しのページは <br /> 見つかりませんでした。
        </p>
      </div>
      <div className="my-4">
        <p>
          ご不明な点がありましたら、お手数ですが
          <br />
          サポートチームまで
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSelj31kIYQmw1Nl_ei1X5GkkhivsESRzx8Np9hUEFagn6kZag/viewform?usp=sf_link"
            rel="noreferrer"
            className="text-link"
          >
            お問い合わせ
          </a>
          ください。
        </p>
      </div>
      {IS_DEV && (
        <Link className="text-link" to="/branchInfo?bId=720npnhZ54ykMQvPv8d5 ">
          トップページへ戻る(開発用)
        </Link>
      )}
    </div>
  );
};

export default NotFoundPage;
