import { AuthContext } from 'contexts/AuthProvider';
import { LotteryResult } from 'models/lotteryResult';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { getRequest } from '../apiClient';

export const buildLotteryResultCacheKey = (qId: string) => ['lotteryResult', qId];

const getLotteryResult = async (qId: string, token: string) => {
  return await getRequest<LotteryResult>('lottery', { qId }, token);
};
/**
 * 抽選結果を取得する。
 * drawLotteryとは違い新しくくじを引かない。
 * @param qId
 * @returns
 */
export const useLotteryResult = (qId: string) => {
  const { token } = useContext(AuthContext);
  const { data, isLoading, isError } = useQuery(buildLotteryResultCacheKey(qId), () =>
    getLotteryResult(qId, token),
  );
  return { data, isLoading, isError };
};
