import { buildCompletedPath, buildCouponPath } from 'pages/routes';
import { FC, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLotteryResult } from 'repositories/lottery/useLottery';
import ReCoPoError from 'utils/ReCoPoError';
import useBackPreventer from 'utils/useBackPreventer';

import { HeaderInfoContext } from '../../../contexts/HeaderInfoContext';
import { LogEvent, sendLog } from '../../../utils/log';
import { buildQuestionnairePath } from '../routes';
import { AtariCard } from './components/AtariCard';
import { Balloon } from './components/Balloon';
import { HazureCard } from './components/HazureCard';

const LotteryResultPage: FC = () => {
  const { qId, bId } = useParams();
  if (qId === undefined || bId === undefined) {
    throw new ReCoPoError('URLが不正です');
  }
  const { data: lotteryResult, isLoading } = useLotteryResult(qId);
  const { setHeaderInfo } = useContext(HeaderInfoContext);
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderInfo({ pageName: '抽選結果', progress: 80 });
  }, [setHeaderInfo]);

  useBackPreventer('恐れ入りますが\n抽選ページへは戻れません。');

  return (
    <div>
      {lotteryResult ? (
        <>
          <h2 className="my-4 text-lg">抽選結果</h2>
          <div className="my-4 ">
            {lotteryResult.result ? (
              <AtariCard discountRate={lotteryResult.discountRate} />
            ) : (
              <HazureCard />
            )}
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex justify-end">
              <Balloon>
                記述式アンケートに回答で
                <br />
                もれなく{lotteryResult.adDiscountRate * 100}%{' '}
                {lotteryResult.result ? '割引率UP' : '割引獲得'} !!
              </Balloon>
            </div>
            <button
              onClick={() => {
                sendLog(LogEvent.StartQuestinnaireAnswering);
                navigate(buildQuestionnairePath(bId, qId));
              }}
              className="py-2 px-4 mb-4 w-full font-bold text-text bg-main hover:bg-blue-700 rounded"
            >
              アンケート回答へ
            </button>
            {lotteryResult.result ? (
              <button
                onClick={() => {
                  sendLog(LogEvent.GoCouponWithoutAnswering);
                  navigate(buildCouponPath(qId));
                }}
                className="py-2 px-4 mx-auto w-10/12 text-sm font-bold text-text bg-white hover:bg-blue-700 rounded border border-text"
              >
                回答せずクーポンを使う
              </button>
            ) : (
              <button
                onClick={() => {
                  sendLog(LogEvent.BackHomeWithoutAnswering);
                  navigate(buildCompletedPath(qId, false));
                }}
                className="py-2 px-4 mx-auto w-10/12 text-sm font-bold text-text bg-white hover:bg-blue-700 rounded border border-text"
              >
                回答せず終了
              </button>
            )}
          </div>
        </>
      ) : isLoading ? (
        <p>Loading...</p>
      ) : (
        <p>エラーが発生しました。</p>
      )}
    </div>
  );
};

export default LotteryResultPage;
