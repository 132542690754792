import { buildCompletedPath } from 'pages/routes';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import useUseCouponFn from '../../repositories/useUseCoupon';
import useUserInfo from '../../repositories/user/useUserInfo';
import isValidCoupon from '../../utils/isValidCoupon';
import { LogEvent, sendLog } from '../../utils/log';

const useCouponPage = (qId: string) => {
  const { data: userData, isLoading, isError: isUserError } = useUserInfo(true);
  const {
    postFn: postSpendCoupon,
    isLoading: isCouponSpending,
    isError: isCouponSpendError,
  } = useUseCouponFn(qId);
  const navigate = useNavigate();

  const coupon = userData
    ? userData.coupons.filter((c) => isValidCoupon(c) && c.qId === qId)[0]
    : null;

  const discountRate = (coupon?.discountRate ?? 0) * 100;
  const adDiscountRate = (coupon?.adDiscountRate ?? 0) * 100;
  const deadline = coupon?.issueDate ? new Date(coupon.issueDate) : new Date();
  deadline.setHours(deadline.getHours() + 3);

  const isError = isUserError || !coupon; // ローディング終了後なお、クーポンがないとき

  const spendCoupon = useCallback(() => {
    if (!window.confirm('クーポンを利用しますか？\nこの操作は取り消す事ができません。')) {
      return;
    }
    if (coupon) {
      postSpendCoupon()
        .then(() => {
          sendLog(LogEvent.UseCoupon);
          navigate(buildCompletedPath(coupon.qId, true));
          return;
        })
        .catch((e) => {
          sendLog(LogEvent.UseCouponFailure, { reason: e });
        });
    }
  }, [coupon, navigate, postSpendCoupon]);

  return {
    spendCoupon,
    coupon,
    isLoading,
    isError,
    discountRate,
    adDiscountRate,
    deadline,
    isCouponSpending,
    isCouponSpendError,
  };
};

export default useCouponPage;
