import { AuthContext } from 'contexts/AuthProvider';
import { useContext } from 'react';
import { useMutation } from 'react-query';

import { postRequest } from './apiClient';

const postUseCoupon = async (qId: string, uId: string, token: string) => {
  return await postRequest('useCoupon', { qId, uId }, token);
};
const useUseCouponFn = (qId: string) => {
  const { token, uId } = useContext(AuthContext);
  const {
    mutateAsync: postFn,
    isLoading,
    isError,
  } = useMutation(() => postUseCoupon(qId, uId, token));
  return { postFn, isError, isLoading };
};

export default useUseCouponFn;
