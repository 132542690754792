import { FC } from 'react';

import spinner from '../../assets/spinner.gif';

type Props = { message?: string };

const LoadingMessage: FC<Props> = ({ message = '送信しています。' }) => {
  return (
    <p className="text-xs align-middle">
      <img src={spinner} alt="spinner" className="inline w-8" />
      {message}
    </p>
  );
};

export default LoadingMessage;
