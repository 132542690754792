import styled from 'styled-components';

export const Balloon = styled.div`
  position: relative;
  display: inline-block;
  margin: 1em 0;
  padding: 5px 7px;
  min-width: 120px;
  max-width: 100%;
  color: rgb(255, 124, 85);
  font-size: 12px;
  font-weight: 700;
  background: #fff;
  border: solid 1px rgb(255, 124, 85);
  border-radius: 0.5rem;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    bottom: -24px;
    left: 70%;
    margin-left: -15px;
    border: 12px solid transparent;
    border-top: 12px solid #fff;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -27.5px;
    left: 70%;
    margin-left: -17px;
    border: 14px solid transparent;
    border-top: 14px solid rgb(255, 124, 85);
    z-index: 1;
  }
`;
