import { AuthContext } from 'contexts/AuthProvider';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { UserInfoInput as CreateUserInput } from '../../models/userInfo';
import { postRequest } from '../apiClient';
import { buildUserCacheKey } from './useUserInfo';

type CreateUserRes = 'ok';
type UsePostUserInfoOutput = {
  postUserInfoFn: ((args: CreateUserInput) => Promise<CreateUserRes | null>) | null;
  data: CreateUserRes | null;
  isLoading: boolean;
  isError: boolean;
};

const postCreateUser = async (data: CreateUserInput, token: string) => {
  return await postRequest<CreateUserRes>('createUser', data, token);
};

const usePostUserInfo = (): UsePostUserInfoOutput => {
  const { token, uId } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const {
    mutateAsync: postUserInfoFn,
    data,
    isLoading,
    isError,
  } = useMutation((data: CreateUserInput) => postCreateUser(data, token), {
    onSuccess: (_, variables) => {
      // POSTに成功した場合、UserInfoを再取得する。
      queryClient.setQueryData(buildUserCacheKey(uId), {
        ...variables,
        uId,
        coupons: [],
      });
    },
  });
  return { postUserInfoFn, data: data || null, isLoading, isError };
};

export default usePostUserInfo;
