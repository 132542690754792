import ky, { HTTPError } from 'ky';
import ReCoPoError from 'utils/ReCoPoError';

import { API_BASEURL, API_ENDPOINT_SUFFIX } from '../consts';

/**
 * APIリクエストのためのヘッダを用意する。
 * @param token 任意。ログイン必要なエンドポイントには必要。
 * @returns headerオブジェクト
 */
const buildHeader = (
  token?: string,
):
  | {
      Authorization: string;
      'app-version': string;
    }
  | {
      'app-version': string;
    } => {
  return token
    ? {
        Authorization: `Bearer ${token}`,
        'app-version': 'web',
      }
    : {
        'app-version': 'web',
      };
};

/**
 * APIリクエストのための完全なURLを提供する
 * @param endpoint エンドポイント名。先頭と末尾  '/' は不要
 * @returns 完全なAPIのURL
 */
const buildRequestUrl = (endpoint: string) => {
  return `${API_BASEURL}/${endpoint}${API_ENDPOINT_SUFFIX}`;
};

/**
 * POSTリクエストをする。
 * @param endpoint エンドポイント名。先頭と末尾  '/' は不要
 * @param token 任意。ログイン必要なエンドポイントには必要。
 * @param body リクエストボディ
 * @returns レスポンスボディのデータ。型引数を与えられる
 */
export const postRequest = async <T>(
  endpoint: string,
  body: { [key: string | number]: any },
  token?: string,
): Promise<T> => {
  try {
    const headers = buildHeader(token);
    const response = await ky
      .post(buildRequestUrl(endpoint), {
        json: body,
        headers,
      })
      .json<{ data: T }>();
    return response.data;
  } catch (e: any) {
    if (e instanceof HTTPError) {
      const body = await e.response.json();
      throw new ReCoPoError(body?.message || '通信でエラーが発生しました');
    }

    throw new ReCoPoError('通信でエラーが発生しました');
  }
};

export const getRequest = async <T>(
  endpoint: string,
  params: { [key: string | number]: any },
  token?: string,
): Promise<T> => {
  try {
    const headers = buildHeader(token);
    const response = await ky
      .get(buildRequestUrl(endpoint), {
        searchParams: params,
        headers,
      })
      .json<{ data: T }>();
    return response.data;
  } catch (e: any) {
    if (e instanceof HTTPError) {
      const body = await e.response.json();
      throw new ReCoPoError(body?.message || '通信でエラーが発生しました');
    }

    throw new ReCoPoError('通信でエラーが発生しました');
  }
};
