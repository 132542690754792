import { FC } from 'react';
import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import useBranchInfo from 'repositories/useBranchInfo';
import useUserInfo from 'repositories/user/useUserInfo';
import useBid from 'utils/useBid';

import isValidCoupon from '../../utils/isValidCoupon';
import NotFoundPage from '../notFound';
import {
  buildCouponPath,
  buildQuestionnaireWithOutLotteryAndCouponPath,
  buildUserInfoPath,
} from '../routes';
import EvaluatePage from './lottery';
import LotteryResultPage from './lotteryResult';
import QuestionnairePage from './questionnaire';
import { flowPaths } from './routes';

const FlowRedirector: FC = () => {
  const { data: uData, isLoading: isUserLoading } = useUserInfo();
  const bId = useBid();
  const { data: bData, isLoading: isBranchLoading } = useBranchInfo(bId);

  if (isUserLoading || isBranchLoading || !bData) {
    return <p>Loading...</p>;
  }
  if (uData === undefined) {
    return <Navigate to={buildUserInfoPath(bId)} replace={true} />;
  }
  if (!bData.isUseLotteryAndCoupon) {
    return <Navigate to={buildQuestionnaireWithOutLotteryAndCouponPath(bId)} replace={true} />;
  }

  const coupons = uData.coupons.filter((c) => isValidCoupon(c) && c.bId === bId);
  const hasValidCoupon = coupons.length > 0;

  if (hasValidCoupon) {
    return <Navigate to={buildCouponPath(coupons[0].qId)} replace={true} />;
  }
  return <Outlet />;
};

// /flow/*にリクエストがあったときにユーザーの状況によってリダイレクト
const FlowPages: FC = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<FlowRedirector />}>
          <Route index element={<Navigate to={flowPaths.lottery} replace={true} />} />
          <Route path={flowPaths.lottery} element={<EvaluatePage />} />
          <Route path={flowPaths.lotteryResult} element={<LotteryResultPage />} />
          <Route path={flowPaths.questionnaire} element={<QuestionnairePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
};
export default FlowPages;
