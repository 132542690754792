import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useDrawLottery } from 'repositories/lottery/useDrawLottery';
import useBranchInfo from 'repositories/useBranchInfo';
import ReCoPoError from 'utils/ReCoPoError';
import { sendLog, LogEvent } from 'utils/log';

import { buildLotteryResultPath } from '../routes';

const useLotteryPage = (bId: string) => {
  const [star, setStar] = useState(0);
  const { data: branchInfo, isLoading } = useBranchInfo(bId);
  const { drawLotteryFn: postLotteryFn, isSending } = useDrawLottery(bId);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');

  const onRatingChanged = useCallback((newRating: number) => {
    setStar(newRating);
  }, []);

  const drawLottery = useCallback(() => {
    const f = async () => {
      if (branchInfo === null) return;
      try {
        setErrorMessage('');
        const result = await postLotteryFn(star);
        if (result) {
          sendLog(LogEvent.DrawLottery);
          navigate(buildLotteryResultPath(bId, result.qId));
        }
      } catch (e) {
        setErrorMessage(e instanceof ReCoPoError ? e.message : 'エラーが発生しました。');
      }
    };
    f();
  }, [bId, branchInfo, navigate, postLotteryFn, star]);

  return {
    branchInfo,
    star,
    drawLottery,
    errorMessage,
    onRatingChanged,
    isLoading,
    isSending,
  };
};

export default useLotteryPage;
