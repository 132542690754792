import { FC, useContext } from 'react';
import { useNavigate } from 'react-router';

import { HeaderInfoContext } from '../../contexts/HeaderInfoContext';
import Progressbar from '../molecules/ProgressBar';

const Header: FC = () => {
  const { headerInfo } = useContext(HeaderInfoContext);
  const navigate = useNavigate();
  return (
    <div className="grid sticky top-0 grid-cols-3 items-center px-4 h-14 bg-main">
      {headerInfo.backTo ? (
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="mx-3 font-extrabold text-left"
        >
          {'＜'}{' '}
        </button>
      ) : (
        <span />
      )}
      {headerInfo.pageName ? (
        <p className="text-lg text-center align-middle whitespace-nowrap">{headerInfo.pageName}</p>
      ) : (
        <span className="h-5" />
      )}
      {headerInfo.progress ? (
        <div className="justify-self-end">
          <Progressbar progress={headerInfo.progress} />
        </div>
      ) : (
        <span />
      )}
    </div>
  );
};

export default Header;
