import { useEffect } from 'react';

/**
 * ブラウザバックを禁止する
 * @param message アラートで出るエラーメッセージ
 */
const useBackPreventer = (message = '元のページヘは戻れません') => {
  useEffect(() => {
    window.history.pushState(null, '', null);

    const preventer = (e: PopStateEvent) => {
      alert(message);
      e.preventDefault();
      window.history.pushState(null, '', null);
    };

    window.addEventListener('popstate', preventer);

    return () => window.removeEventListener('popstate', preventer, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useBackPreventer;
