/**
 * branchInfo?bId=[bId] * branchInfo/[bId]
 *                      * personalInfo?redirect=[bId]
 *                      * flow/[bId]/lottery              lottery/[bId]
 *                      * flow/[bId]/lotterResult/[qId]   lotteryResult/[qId]
 *                      * flow/[bId]/questionnaire/[qId]  questionnaire/[qId]
 * coupon               * coupon/[qId]
 * completed            * completed/[qId]
 */

/**
 * アプリのroot直下のパス
 * これらの配下のパスは各ディレクトリでRoute定義する
 */
export const paths = {
  branch: '/branch/:bId',
  personalInfo: '/personalInfo',
  flow: '/flow/:bId',
  questionnaireWithOutLotteryAndCoupon: 'star-detail/:bId',
  completed: '/completed/:qId',
  coupon: '/coupon/:qId',
  error: '/error',
  userInfo: '/userInfo',
} as const;

export const buildBranchPath = (bId: string) => `/branch/${bId}`;
export const buildFlowPath = (bId: string) => `/flow/${bId}`;
export const buildCouponPath = (qId: string) => `/coupon/${qId}`;
export const buildCompletedPath = (qId: string, isCouponUsed: boolean) =>
  `/completed/${qId}?isCouponUsed=${isCouponUsed}`;
export const buildUserInfoPath = (bId: string) => `/userInfo?redirect=${bId}`;
export const buildQuestionnaireWithOutLotteryAndCouponPath = (bId: string) => `/star-detail/${bId}`;
