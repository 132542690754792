import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';

export const DebugView = () => (
  <div className="bg-sub">
    <h1 className="text-xl">デバッグ(開発用)</h1>
    <ReactQueryDevtools
      toggleButtonProps={{
        style: { display: 'block', position: 'relative', height: '2rem' },
      }}
      initialIsOpen={false}
    />
  </div>
);
