import { DebugView } from 'components/organisms/DebugView';
import Header from 'components/organisms/Header';
import { IS_DEV } from 'consts';
import AppProvider from 'contexts/AppProvider';
import AuthProvider from 'contexts/AuthProvider';
import 'firebase/analytics';
import firebase from 'infrastructure/firebase';
import queryClient from 'infrastructure/queryClient';
import Pages from 'pages/Index';
import { FC, useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Routeの変更をgoogle analyticsに通知する.
 */
const PageAnalyst: FC = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    firebase.analytics().logEvent('screen_view');
  }, [location]);
  return <>{children}</>;
};

const App = () => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppProvider>
            {/* ReactRouterを使えるようにする */}
            <BrowserRouter>
              {/* GA */}
              <PageAnalyst>
                <Header />
                <div className="flex justify-center my-10 mx-5 md:container md:mx-auto ">
                  <Pages />
                  <ToastContainer
                    position="bottom-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </div>
                {IS_DEV && <DebugView />}
              </PageAnalyst>
            </BrowserRouter>
          </AppProvider>
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
