import { AuthContext } from 'contexts/AuthProvider';
import { UserInfo } from 'models/userInfo';
import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { postRequest } from '../apiClient';

const getUserInfo = async (uId: string, token: string) => {
  return await postRequest<UserInfo>('getUserInfo', { uId }, token);
};

export const buildUserCacheKey = (uId: string) => ['userInfo', uId];

const useUserInfo = (refresh?: boolean) => {
  const auth = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const cacheKey = buildUserCacheKey(auth.uId);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  if (isFirstRender && refresh) {
    queryClient.removeQueries(cacheKey);
  }
  const { data, isLoading, isError, error } = useQuery(cacheKey, () =>
    getUserInfo(auth.uId, auth.token),
  );
  return { data, isLoading, isError, errMsg: error };
};

export default useUserInfo;
