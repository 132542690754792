import { FC } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { paths, buildBranchPath } from '../routes';

// 旧パスの /branchInfo?bId=:bId にリクエストが有った時に、新パスの /branch/:bIdに遷移させる。
// QR刷新後旧パスへのリクエストを計測できなくなったタイミングで消去する
const BranchInfoRedirector: FC = () => {
  const [params] = useSearchParams();
  const unsafeBId = params.get('bId');
  return unsafeBId ? (
    <Navigate to={buildBranchPath(unsafeBId)} replace={true} />
  ) : (
    <Navigate to={paths.error} />
  );
};

export default BranchInfoRedirector;
