import { FC } from 'react';

import Card from '../../../components/molecules/Card';
import { BranchInfo } from '../../../models/branchInfo';

type Props = {
  data: BranchInfo;
};
const Coupon: FC<Props> = ({ data }) => {
  return (
    <Card
      title={
        <>
          <p className="text-sm font-bold text-center text-text">
            かんたん 2ステップで本日のお会計が
          </p>
          <p className="text-center">
            <span className="font-bold text-text">
              最大{' '}
              <span className="text-5xl text-emphasize">
                {((data.lotteryDiscountRate + data.questionnaireDiscountRate) * 100).toFixed(0)}
              </span>
              % OFF !!
            </span>
          </p>
        </>
      }
      className="border-4  border-main"
    >
      <div className="grid grid-cols-1 gap-0 justify-items-start">
        <h4 className="font-bold text-sub">内訳</h4>
        <p>
          <span className="text-sm text-text">絶対にもらえる!! アンケート割引 </span>
          <span className="font-semibold text-emphasize">
            {data.questionnaireDiscountRate * 100}%
          </span>
        </p>
        <p>
          <span className="text-sm text-text">
            {data.lotteryProbability * 100}%で当たる!! 抽選割引{' '}
          </span>
          <span className="font-semibold text-emphasize">{data.lotteryDiscountRate * 100}%</span>
        </p>
      </div>
      <hr className="my-2 text-grey" />
      <div className="grid grid-cols-1 gap-0 justify-items-start">
        <h4 className="font-bold text-sub">クーポン利用期限</h4>
        <p>当選後3時間以内まで</p>
        <p className="text-xs">※ クーポンの併用はできません。</p>
      </div>
    </Card>
  );
};

export default Coupon;
