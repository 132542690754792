import { AuthContext } from 'contexts/AuthProvider';
import { useContext } from 'react';
import { useMutation } from 'react-query';

import { drawLottery } from './lottery/useDrawLottery';
import { postQuestionnaire } from './usePostQuestionnaire';

const postQuestionnaireWithoutLotteryAndCoupon = async (
  uId: string,
  bId: string,
  star: number,
  detail: string,
  token: string,
) => {
  const result = await drawLottery({ uId, bId, star }, token);
  postQuestionnaire(result.qId, detail, token);
  return result.qId;
};

const usePostQuestionnaireWithoutLotteryAndCoupon = (bId: string) => {
  const { token, uId } = useContext(AuthContext);
  const { mutateAsync: postFn, isLoading: isSending } = useMutation(
    ({ star, detail }: { star: number; detail: string }) =>
      postQuestionnaireWithoutLotteryAndCoupon(uId, bId, star, detail, token),
  );

  return { postFn, isSending };
};

export default usePostQuestionnaireWithoutLotteryAndCoupon;
