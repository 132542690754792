import { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import { getRequest } from 'repositories/apiClient';

type AddrApiRes = {
  success: true;
  address1: string;
  address2: string;
} | null;
const getAddress = async (zipcode: string) => {
  return await getRequest<AddrApiRes>('getAddress', { zipcode });
};

type Props = {
  setPrefecture: (p: string) => void;
  setMunicipality: (p: string) => void;
  prefecture: string;
  municipality: string;
};
const useAddressInput = ({ setPrefecture, setMunicipality, prefecture, municipality }: Props) => {
  const { mutateAsync: getAddressFn, isLoading } = useMutation(getAddress);

  const [errorMessage, setErrorMessage] = useState('');

  const address = prefecture && municipality ? `${prefecture} ${municipality}` : '';

  const checkZipcode = useCallback((zipcode: string) => {
    if (!zipcode.match(/^\d{3}-?\d{4}$/)) {
      setErrorMessage('郵便番号の形式が間違っています。');
      return false;
    } else return true;
  }, []);

  const searchAddress = useCallback(
    (zipcode: string) => {
      const f = async () => {
        setPrefecture('');
        setMunicipality('');
        setErrorMessage('');
        if (checkZipcode(zipcode)) {
          try {
            const res = await getAddressFn(zipcode);
            if (res) {
              setPrefecture(res.address1);
              setMunicipality(res.address2);
            } else {
              setPrefecture('');
              setMunicipality('');
            }
          } catch {
            setErrorMessage('存在しない郵便番号です。');
          }
        }
      };
      f();
    },
    [checkZipcode, getAddressFn, setMunicipality, setPrefecture],
  );

  return {
    address,
    checkZipcode,
    searchAddress,
    errorMessage,
    isLoading,
  };
};

export default useAddressInput;
