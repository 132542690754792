import { AuthContext } from 'contexts/AuthProvider';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { postRequest } from 'repositories/apiClient';

import { LotteryResult as LotteryResponse } from '../../models/lotteryResult';
import { buildLotteryResultCacheKey } from './useLottery';

type DrawLotteryInput = {
  uId: string;
  bId: string;
  star: number;
};
export const drawLottery = async (inputs: DrawLotteryInput, token: string) => {
  return postRequest<LotteryResponse>('lottery', inputs, token);
};
/**
 * 抽選を引く
 * @param bId
 * @returns
 */
export const useDrawLottery = (bId: string) => {
  const auth = useContext(AuthContext);
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (star: number) =>
      drawLottery(
        {
          star,
          uId: auth.uId,
          bId,
        },
        auth.token,
      ),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(buildLotteryResultCacheKey(data.qId), data);
      },
    },
  );

  return {
    drawLotteryFn: mutation.mutateAsync,
    data: mutation.data,
    isSending: mutation.isLoading,
    isError: mutation.isError,
  };
};
