import { AuthContext } from 'contexts/AuthProvider';
import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import useBranchInfo from 'repositories/useBranchInfo';
import usePostUserInfo from 'repositories/user/usePostUserInfo';
import useToast from 'services/useToast';
import ReCoPoError from 'utils/ReCoPoError';

import ageToBirthday from '../../utils/ageToBirthday';
import { LogEvent, sendLog } from '../../utils/log';
import logger from '../../utils/logger';
import { buildFlowPath, paths } from '../routes';

const usePersonalInfoPage = () => {
  const { postUserInfoFn, isLoading: isPosting } = usePostUserInfo();
  const [searchParams] = useSearchParams();
  const bId = searchParams.get('redirect');
  if (!bId) throw new ReCoPoError('店舗IDが指定されていません');
  const { data: branch } = useBranchInfo(bId);
  const navigate = useNavigate();
  const { uId } = useContext(AuthContext);

  const [prefecture, setPrefecture] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    // isValidは不安定なので使わない
  } = useForm<
    {
      affiliation: string;
      age: number;
      gender: string;
      zipcode: string;
    },
    object
  >({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      affiliation: '',
      gender: '',
      zipcode: '',
    },
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const isValid =
    !!watch('affiliation') && !!watch('age') && !!watch('gender') && !!prefecture && !!municipality;

  const submit = useCallback(
    async (data: { affiliation: string; age: number; gender: string }) => {
      setErrMsg('');
      if (!postUserInfoFn || !uId || !isValid) {
        setErrMsg('入力に誤りがあります');
        return;
      }

      try {
        const userInfo = {
          uid: uId,
          birthday: ageToBirthday(data.age),
          mail: 'dummy@example.com',
          gender: data.gender,
          municipality: municipality,
          nickName: 'web user',
          prefecture: prefecture,
          affiliation: data.affiliation,
        };
        await postUserInfoFn(userInfo);
        sendLog(LogEvent.SignUpSuccess);
        if (bId) navigate(buildFlowPath(bId));
        else navigate(paths.error);

        if (branch && !branch.isUseLotteryAndCoupon) {
          toast(
            <>
              回答ありがとうございます。
              <br />
              これが最後のステップです。
            </>,
          );
        }

        return;
      } catch (e) {
        logger.error(e);
        sendLog(LogEvent.SignUpFailureOtherReason);
        setErrMsg('送信に失敗しました。');
      }
    },
    [bId, branch, isValid, municipality, navigate, postUserInfoFn, prefecture, toast, uId],
  );

  return {
    submit,
    isValid,
    register,
    handleSubmit,
    isPosting,
    errMsg,
    prefecture,
    setPrefecture,
    municipality,
    setMunicipality,
    branch,
  };
};

export default usePersonalInfoPage;
