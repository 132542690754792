import { createContext, FC, useState } from 'react';

export type IPath = string;
type IHeaderInfo = {
  pageName: string;
  backTo?: IPath | number;
  progress?: number;
};
export const HeaderInfoContext = createContext({
  headerInfo: {
    pageName: '',
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setHeaderInfo: (hInfo: IHeaderInfo) => {},
} as {
  headerInfo: IHeaderInfo;
  setHeaderInfo: (hInfo: IHeaderInfo) => void;
});

const HeaderInfoProvider: FC = ({ children }) => {
  const [headerInfo, setHeaderInfo] = useState<IHeaderInfo>({ pageName: '' });
  return (
    <HeaderInfoContext.Provider value={{ headerInfo, setHeaderInfo }}>
      {children}
    </HeaderInfoContext.Provider>
  );
};

export default HeaderInfoProvider;
