import { FC } from 'react';

import HeaderInfoProvider from './HeaderInfoContext';

type Props = {
  children: React.ReactNode;
};
const AppProvider: FC<Props> = ({ children }) => {
  return <HeaderInfoProvider>{children}</HeaderInfoProvider>;
};

export default AppProvider;
