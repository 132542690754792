import Skeleton from 'components/atoms/skeleton';
import Coupon from 'pages/branch/components/Coupon';
import { buildFlowPath } from 'pages/routes';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBranchInfo from 'repositories/useBranchInfo';
import useHeader from 'services/useHeader';
import ReCoPoError from 'utils/ReCoPoError';
import { LogEvent, sendLog } from 'utils/log';

const BranchInfoPage: FC = () => {
  useHeader('店舗情報');
  const navigate = useNavigate();
  const unsafeBId = useParams().bId;
  if (!unsafeBId) {
    throw new ReCoPoError('店舗が見つかりませんでした');
  }
  const bId = unsafeBId;

  const { data, isLoading, isError } = useBranchInfo(bId);

  const location = data && `${data.prefecture} ${data.municipality}`;

  useEffect(() => {
    if (data && data.redirectTo) {
      window.location.href = data.redirectTo;
    }
  }, [data]);
  if (isError) return <div>店舗情報の取得でエラーが発生しました</div>;

  return (
    <>
      <div className="flex flex-col space-y-6 w-full">
        <div>
          <h2 className="my-4 text-xl">
            {data && data.branchName}
            {!data && <Skeleton />}
          </h2>
          <div className="my-4">
            <div className="grid grid-cols-2 gap-4 max-w-md">
              <h4 className="mx-4">所在地</h4>
              <p className="mx-4 font-light">
                {location || <Skeleton className="mx-4 font-light" />}
              </p>
            </div>
            <hr className="my-2 text-grey" />

            <div className="grid grid-cols-2 gap-4 max-w-md">
              <h4 className="mx-4">電話番号</h4>
              <p className="mx-4 font-light">
                {data && data.phoneNumber}
                {!data && <Skeleton />}
              </p>
            </div>
          </div>
        </div>
        {!data && <Skeleton height={50} />}
        {data && data.isUseLotteryAndCoupon && <Coupon data={data} />}
        {data && !data.isUseLotteryAndCoupon && (
          <p>店舗をより良くするためにかんたんなアンケートに回答をお願いします。</p>
        )}
        <div className="flex flex-col justify-around">
          <button
            onClick={() => {
              navigate(buildFlowPath(bId));
              sendLog(LogEvent.GoFlow);
            }}
            disabled={isLoading || isError}
            className="py-2 px-4 w-full font-bold text-text bg-main rounded"
          >
            次へ
          </button>
          <p className="my-2 text-xs">
            <a href="/terms.html" target="_blank" className="text-link underline">
              利用規約
            </a>
            と
            <a href="/privacy.html" target="_blank" className="text-link underline">
              プライバシーポリシー
            </a>
            に
            <br />
            同意の上ご利用ください。
          </p>
        </div>
      </div>
    </>
  );
};

export default BranchInfoPage;
