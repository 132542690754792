import LoadingMessage from 'components/molecules/LoadingMessage';
import { HeaderInfoContext } from 'contexts/HeaderInfoContext';
import { paths } from 'pages/routes';
import { FC, useContext, useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import ReactStars from 'react-stars';
import ReCoPoError from 'utils/ReCoPoError';

import useLotteryPage from './useLotteryPage';

const EvaluatePage: FC = () => {
  const unsafeBId = useParams().bId;
  if (!unsafeBId) {
    throw new ReCoPoError('店舗が見つかりませんでした');
  }
  const bId = unsafeBId;
  const { setHeaderInfo } = useContext(HeaderInfoContext);
  const { star, branchInfo, drawLottery, errorMessage, onRatingChanged, isLoading, isSending } =
    useLotteryPage(bId);

  useEffect(() => {
    setHeaderInfo({
      pageName: '抽選',
      backTo: -1,
      progress: 50,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <p>Loading...</p>; // FIXME共通ローディングコンポーネントに置き換え
  if (branchInfo === null) return <Navigate to={paths.error} />;

  return (
    <div className="w-full">
      <p>
        {branchInfo.branchName}を5段階で <br />
        総合評価してください。
      </p>

      <div className="flex justify-center py-4 mt-8 mb-2 rounded-lg border-2 border-grey">
        {/* Editオプションは初期化時のみ有効っぽかった */}
        <ReactStars
          count={5}
          value={star}
          onChange={onRatingChanged}
          half={false}
          size={48}
          color2={'#ffd700'}
        />
      </div>
      <p className="mb-8 text-xs text-right">抽選後に評価の変更はできません。</p>
      <button
        className={` ${
          star > 0 && !isSending ? 'bg-main' : 'bg-grey'
        } hover:bg-blue-700 text-text font-bold py-2 px-4 rounded w-full`}
        disabled={star === 0 || isSending}
        onClick={drawLottery}
      >
        抽選を引く
      </button>
      <p className="mt-2 text-sm text-error"> {errorMessage}</p>
      {isSending && <LoadingMessage />}
    </div>
  );
};
export default EvaluatePage;
