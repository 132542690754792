import Skeleton from 'components/atoms/skeleton';
import LoadingMessage from 'components/molecules/LoadingMessage';
import { buildCompletedPath, paths } from 'pages/routes';
import { useCallback, useState, VFC } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ReactStars from 'react-stars';
import useBranchInfo from 'repositories/useBranchInfo';
import usePostQuestionnaireWithoutLotteryAndCoupon from 'repositories/usePostQuestionnaireWithoutLotteryAndCoupon';
import useHeader from 'services/useHeader';
import useToast from 'services/useToast';
import ReCoPoError from 'utils/ReCoPoError';
import { LogEvent, sendLog } from 'utils/log';
import useBid from 'utils/useBid';

const OnlyQuestionnairePage: VFC = () => {
  const [star, setStar] = useState(0);
  const [errMsg, setErrMsg] = useState('');
  const bId = useBid();

  const { data: branchInfo, isLoading } = useBranchInfo(bId);
  const { postFn, isSending } = usePostQuestionnaireWithoutLotteryAndCoupon(bId);

  const toast = useToast();

  const navigate = useNavigate();

  const onRatingChanged = useCallback((newRating: number) => {
    setStar(newRating);
  }, []);

  useHeader('アンケート', 100);

  const [detail, setDetail] = useState('');
  const onAnswerChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setDetail(e.target.value);
  };

  const disabled = star < 1 || star > 5 || detail.length === 0 || isSending;
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        if (star < 1 || star > 5) {
          setErrMsg('星を入力してください。');
          return;
        }
        if (detail.length === 0) {
          setErrMsg('お店に対してのご意見をお聞かせください。');
          return;
        }
        const qId = await postFn({ star, detail });
        sendLog(LogEvent.SendQuestionnaireAnswerWithOutLottery);
        navigate(buildCompletedPath(qId, false));
        toast(
          <>
            回答を記録しました🎉
            <br />
            回答いただきありがとうございました。
          </>,
        );
      } catch (e) {
        setErrMsg(e instanceof ReCoPoError ? e.message : '送信でエラーが発生しました');
      }
    },
    [detail, navigate, postFn, star, toast],
  );

  if (!isLoading && branchInfo === null) return <Navigate to={paths.error} />;
  return (
    <div className="w-full">
      <form onSubmit={onSubmit} className="flex flex-col space-y-6">
        <div className="flex flex-col space-y-2">
          {branchInfo ? (
            <p>{branchInfo.branchName}を5段階で総合評価してください。</p>
          ) : (
            <Skeleton />
          )}
          <div className="flex justify-center py-4 rounded-lg border-2 border-grey">
            <ReactStars
              count={5}
              value={star}
              onChange={onRatingChanged}
              half={false}
              size={48}
              color2={'#ffd700'}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <p>
            利用したお店に対して、料理・雰囲気・場所・サービス・価格などについて、より具体的なご意見をお聞かせください。
          </p>
          <div className="flex justify-center h-52">
            <textarea
              onChange={onAnswerChange}
              className="p-2 w-full h-full rounded-lg border-2 border-grey border-solid outline-none"
              disabled={isSending}
            />
          </div>
        </div>
        <button
          className={`${
            disabled ? 'bg-grey' : 'bg-main'
          }  text-blue font-bold py-2 px-4 rounded-lg w-full`}
          disabled={disabled}
        >
          送信して終了
        </button>
        {isSending && <LoadingMessage />}
        {errMsg}
      </form>
    </div>
  );
};

export default OnlyQuestionnairePage;
