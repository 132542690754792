export const AppColor = {
  main: 'rgb(255, 214, 133)',
  sub: 'rgb(148, 156, 101)',
  link: 'rgb(148, 156, 101)',
  emphasize: 'rgb(255, 124, 85)',
  error: 'rgb(255, 79, 39)',
  text: 'rgb(63, 37, 19)',
  white: 'rgb(255, 255, 255)',
  grey: 'rgb(196, 196, 196)',
};
