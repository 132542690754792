import { AuthContext } from 'contexts/AuthProvider';
import { useContext } from 'react';
import { useMutation } from 'react-query';

import { postRequest } from './apiClient';

export const postQuestionnaire = async (qId: string, detail: string, token: string) => {
  return await postRequest('register', { qId, detail }, token);
};

const usePostQuestionnaire = (qId: string) => {
  const { token } = useContext(AuthContext);
  const { mutateAsync: postQuestionnaireFn, isLoading: isSending } = useMutation((detail: string) =>
    postQuestionnaire(qId, detail, token),
  );

  return { postQuestionnaireFn, isSending };
};

export default usePostQuestionnaire;
