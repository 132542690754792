import { HeaderInfoContext } from 'contexts/HeaderInfoContext';
import { useContext, useEffect } from 'react';

const useHeader = (pageName: string, progress?: number) => {
  const { setHeaderInfo } = useContext(HeaderInfoContext);
  useEffect(() => {
    setHeaderInfo({
      pageName,
      progress,
    });
  }, [pageName, progress, setHeaderInfo]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export default useHeader;
